<template>
  <div style="min-height:100%;">
    <div
      v-if="loading.tryGetSettings"
      class="d-flex flex-row justify-center align-center"
      style="position:absolute;left:0px;top:0px;width:100%;height:200px;"
    >
      Lade Daten...
    </div>
    <!-- $store.state.login.app.url?.split('http://').join('https://') -->
    <v-parallax
      :src="`/img/vLogin/${pagesettings.images?.bg}.jpg`"
      :style="`height:${pagesettings.header.height}${pagesettings.header.heightUnit};transition:0.8s;`"
    >
    <div
      class="d-flex flex-column justify-center align-center"
      style="min-height:100%;"
    >
      <a :href="pagesettings.header.logoLink" target="_blank"
        :style="{
          'pointer-events': pagesettings.header.logoLink === '' ? 'none' : 'auto',
        }"
      >
        <!-- $store.state.login.app.url?.split('http://').join('https://') -->
        <v-img
          :src="`/img/vLogin/${pagesettings.images?.logo}.png`"
          :style="{
            width: `${pagesettings.header.logoWidth}%`,
            'max-width': pagesettings.header.logoMaxWidth,
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            transition: '1.8s',
            'transition-delay': '0.2s',
          }"
          alt=""
        ></v-img>
      </a>
    </div>
    </v-parallax>
    <v-row
      :style="{
        'min-height': `calc(100vh - ${pagesettings.header.height}${pagesettings.header.heightUnit})`,
      }"
      class="ma-1"
    >
      <v-col
        cols="12" md="6"
        class="d-flex flex-column justify-center align-center"
      >
        <div style="width:100%;max-width:500px;">
          <v-combobox
            v-if="$store.state.login.settings.realms.length > 1"
            :label="$store.state.login.settings.texte?.selectRealm"
            :items="$store.state.login.settings.realms"
            item-title="name"
            item-value="id"
            v-model="nutzer.realm"
            @update:model-value="switchRealm();"
          ></v-combobox>
          <v-text-field
            label="Benutzername"
            v-model="nutzer.nutzername"
            :disabled="loading.tryGetSettings || loading.tryLogin"
          />
          <v-text-field
            type="password"
            v-model="nutzer.passwort"
            label="Passwort"
            :disabled="loading.tryGetSettings || loading.tryLogin"
            @keyup.enter="doLogin();"
            ref="pwInput"
          />
          <v-btn
            block
            :color="$vuetify.theme.current.colors['primary']"
            :style="`color:${$vuetify.theme.current.colors['primary-darken-2-text']};`"
            class="mb-4"
            :loading="loading.tryLogin"
            :disabled="
              loading.tryGetSettings
              || (
                !nutzer.realm
                && $store.state.login.settings.realms.length > 1
              )
              || !nutzer.realm
            "
            @click="doLogin();"
          >
            Einloggen
          </v-btn>
          <div 
            v-if="nutzer.realm"
            class="d-flex flex-row justify-space-evenly"
          >
            <vLoginRegistrieren
              v-if="$store.state.login.settings.reg?.active === '1'"
              :idRealm="nutzer.realm.id"
            />
            <div></div>
            <!--
            <div>
              Passwort vergessen?
            </div>
            -->
          </div>
        </div>
      </v-col>
      <v-col
        cols="12" md="6"
        class="d-flex flex-column justify-center"
      >
        <div v-if="loading.tryGetSettings">
          <v-skeleton-loader type="article" color="background"></v-skeleton-loader>
        </div>
        <v-expansion-panels v-else>
          <v-expansion-panel
            v-for="i in pagesettings.infos"
            :key="i.id"
          >
          <v-expansion-panel-title
            :color="$vuetify.theme.current.colors['primary-darken-2']"
            :style="`color:${$vuetify.theme.current.colors['primary-darken-2-text']};`"
          >
            {{ i.titel }}
          </v-expansion-panel-title>
          <v-expansion-panel-text
            class="pa-2"
            :style="`color:${$vuetify.theme.current.colors['background-lighten-2-text']};background-color:${$vuetify.theme.current.colors['background-lighten-2']};`"
          ><mainHTML :html="i.html" /></v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import mainHTML from '@/components/main/mainHTML.vue';
import vLoginRegistrieren from '@/components/login/vLogin-registrieren.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'vLogin',

  components: {
    mainHTML,
    vLoginRegistrieren,
  },
  data: () => ({
    nutzer: {
      realm: null,
      nutzername: '',
      passwort: '',
    },
  }),
  computed: {
    pagesettings() {
      return this.$store.state.login.settings;
    },
    loading() {
      return this.$store.state.login.loading;
    },
    currentRealm() {
      return this.$store.state.login.app.realm;
    },
  },
  watch: {
    currentRealm: {
      handler(neu) {
        if (neu.id > 0) {
          this.nutzer.realm = neu;
        } else {
          this.nutzer.realm = null;
        }
      },
      deep: true,
    }
  },
  methods: {
    doLogin() {
      this.$refs.pwInput.blur();
      this.$store.dispatch('login/tryLogin', { nutzer: this.nutzer });
    },
    switchRealm() {
      this.$store.dispatch('login/tryGetSettings', this.nutzer.realm?.idname);
    },
  },
  mounted() {
    document.title += " - Login";
    
    this.$store.dispatch('login/tryGetSettings');
  },
  created() {
    if (this.$router.currentRoute.value.query.usr) {
      this.nutzer.nutzername = decodeURI(this.$router.currentRoute.value.query.usr);
    }
  },
});
</script>
