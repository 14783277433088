<template>
  <div>
    <v-btn @click="tryGetSettings();">Aktualisieren</v-btn>
    <br/><br/>
    <v-container>
      <b>Gesperrte IP-Adressen:</b>
      <br/>
      <div v-if="Object.keys(errorLogs).length <= 0">
        <i>Keine gesperrten IP-Adressen</i>
      </div>
      <div v-else>
        <div
          v-for="ip in Object.keys(errorLogs)"
          :key="ip"
          class="pa-1"
        >
          <b>{{ ip }}</b>
          <v-row no-gutters>
            <v-col cols="4">{{ errorLogs[ip].date_last_de }}</v-col>
            <v-col cols="4">{{ errorLogs[ip].count }}/{{ bruteForceSettings.system_bruteForce_errorCount }}</v-col>
            <v-col cols="4">
              <v-btn size="small" @click="tryBruteForceIpFreigeben(ip);">freigeben</v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>

export default {
  name: 'c000002setSettings',
  
  components: {
  },
  props: {
  },
  data: () => ({
    loading: false,
    bruteForceSettings: [],
    errorLogs: [],
  }),
  watch: {
  },
  methods: {
    tryGetSettings() {
      this.loading = true;
      this.$weFetch({
        path: '000-002/?set&getSettings',
        data: {},
      }).then((res) => {
        this.loading = false;
        if (res.status === 'success') {
          this.bruteForceSettings = res.data.bruteForceSettings;
          this.errorLogs = res.data.errorLogs;
        } else {
          //
        }
      });
    },
    tryBruteForceIpFreigeben(ip) {
      this.loading = true;
      this.$weFetch({
        path: '000-002/?set&bruteForceIpFreigeben',
        data: {
          ip,
        },
      }).then((res) => {
        this.loading = false;
        if (res.status === 'success') {
          this.tryGetSettings();
        } else {
          //
        }
      });
    }
  },
  created() {
    this.tryGetSettings();
  },
}
</script>
